import React, { useState, useEffect } from "react";
import classes from './landingPage.module.css'
import { useNavigate } from "react-router-dom";
import { NavLink, Link, Navigate } from 'react-router-dom';
import { Button, Carousel, Spinner } from "react-bootstrap";
import HeaderNav from '../../Components/HeaderNav/HeaderNav'
import HeaderNavtu from '../../Components/HeaderNavLandingpage/HeaderNavtu'
import Invest1 from "../../assets/Invest-icon.svg"
import Dapo from "../../assets/landingpage/dapoo.png";
import footerLogo from "../../assets/Footer-logo.svg"
import OgunMap from '../../assets/ogunmap2.svg';
import Picture1 from '../../assets/carousels/picture1.png'
import Picture1mble from '../../assets/carousels/picture1mble.png'
import Picture2 from '../../assets/carousels/picture2.png'
import Picture3 from '../../assets/carousels/picture3.png'
import Picture4 from '../../assets/carousels/picture4.png'
import Picture5 from '../../assets/carousels/picture5.png'
import Picture6 from '../../assets/carousels/picture6.png'
import Pic1 from '../../assets/news/pic1.png'
import Pic2 from '../../assets/news/pic2.png'
import Pic3 from '../../assets/news/pic3.png'
import Pic4 from '../../assets/news/pic4.png'
import Pic5 from '../../assets/news/pic5.png'
import Pic6 from '../../assets/news/pic6.png'
import Landing1 from '../../assets/landingpage/landing1.png'
import Landing2 from '../../assets/landingpage/landing2.png'
import Landing3 from '../../assets/landingpage/landing3.png'
import Landing4 from '../../assets/landingpage/landing4.png'
import Icon1 from '../../assets/landingpage/ic1.png'
import Icon2 from '../../assets/landingpage/ic2.png'
import Icon3 from '../../assets/landingpage/ic3.png'
import Icon4 from '../../assets/landingpage/ic4.png'
import Icon5 from '../../assets/landingpage/tick-circle.png'
import Icon6 from '../../assets/landingpage/tick-circle1.png'
import herobg from '../../assets/carousels/homebgmble.png'
import NewsIcon from '../../assets/olumo.png'
import NewFooter from "../../Components/NewFooter";
import axios from "axios";
import { BASE_URL, TOKEN } from "../../BaseUrl/BaseUrl";
import { Modal } from 'react-bootstrap';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';


// function App() {
const LandingPage = () => {
  const navigate = useNavigate();
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState(0);
  const [active, setActive] = useState(false);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const [iconSrc, setIconSrc] = useState(Icon5);
  const [showPopup, setShowPopup] = useState(false);

  // useEffect(() => {
  //   const hasClosed = sessionStorage.getItem('privacyClosed');
  //   if (!hasClosed) {
  //     setShowPopup(true);
  //   }
  // }, []);

  // const closePopup = () => {
  //   setShowPopup(false);
  //   sessionStorage.setItem('privacyClosed', 'true'); // Remember this session
  // };






  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const handleOgstep = () => {
    navigate('/ogstep_documents');
  };

  const carouselItems = [
    { id: 1, img: Picture1, text: 'OGUN STATE', },
    { id: 2, img: Picture2, text: 'OGUN STATE', },
    { id: 3, img: Picture3, text: 'OGUN STATE', },
    { id: 4, img: Picture4, text: 'OGUN STATE', },
    { id: 5, img: Picture5, text: 'OGUN STATE', },
    { id: 6, img: Picture6, text: 'OGUN STATE', },
  ];

  const landingPage = [
    { id: 1, img: Landing1, headerText: "The Gateway State", text: 'Ogun State was created on the 3rd of February, 1976.  It was carved out of the old Western State of Nigeria and named after the Ogun River which runs across it from North to South.' },
    { id: 2, img: Landing2, headerText: "Our Culture", text: 'Some of the well known festivals are:Egungun, Agemo, Igunnuko, Ogun, Orisa-Oko, Gelede, Eyo, Oro, Ifa, Sango, Obatala, Osun and Akoogun.', },
    { id: 3, img: Landing3, headerText: "Our Heritage", text: 'Ogun State is predominantly Yoruba, with the Yoruba language serving as the lingua franca of the state. The dominant religions in Ogun State are Islam and Christianity although a certain amount of traditional religion is still practiced. Ogun State is noted for being the almost exclusive site of Ofada rice production.', },
    { id: 4, img: Landing4, headerText: "Our People", text: 'It is people predominantly by the Egbas, Ijebus, Yewas, Remos and Aworis; - who belong to the main Yoruba ethnic group.  It also has sub-groups, namely, Ketu, Ikale, Ilaje, Ohori, Anago and Egun. There are also significant numbers of Nigerians from other parts of the country as well as foreign nationals resident in the State.', },
  ];

  landingPage.forEach(item => {
    const words = item.headerText.split(' ');
    item.headerText = `${words[0]}<br>${words.slice(1).join(' ')}`;
  });


  useEffect(() => {
    const handleScroll = () => {
      const section = document.querySelector(`.${classes.sectionEight}`);
      if (section) {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;
        const windowHeight = window.innerHeight;
        const scrollY = window.scrollY || window.pageYOffset;
        const isVisible = scrollY + windowHeight > sectionTop && scrollY < sectionTop + sectionHeight;

        if (isVisible) {
          setActive(true);
          window.removeEventListener('scroll', handleScroll);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check on initial render

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);





  // const App = () => {
  const isMobile = window.innerWidth <= 768;

  const handleNavigateToHome = () => {
    navigate('/')
  }

  const fetchNews = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/get-news`, {
        headers: {
          'Authorization': `Bearer ${TOKEN}`,
          'Content-Type': 'application/json'
        },
      });
      const results = response?.data?.data?.data;
      setDocuments(results);
      console.log(results);
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNews();
  }, []);

  function formatDate(dateString) {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    const date = new Date(dateString);
    const dayOfWeek = days[date.getDay()];
    const month = months[date.getMonth()];
    const dayOfMonth = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    const formattedDate = `${dayOfWeek}, ${month}, ${dayOfMonth} ${year} at ${hours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
    return formattedDate;
  }

  const handleReadMore = (item) => {
    navigate('/read_news', { state: { selectedNews: item } });
  }
  const handleInvest = () => {
    window.open("http://invest.ogunstate.gov.ng", "_blank");

  }


  return (
    <div>


      <HeaderNav className={classes.mobilMobileNav} />



      {/* <HeaderNavtu  className={classes.webheader}/> */}
      {/* <HeaderNav  className={classes.mbleheader}/> */}
      <div className={classes.generalBody}>


        <div className={classes.wrapper}>
          <Carousel interval={5000} slide indicators={false} style={{ position: 'relative' }} controls={false} className={classes.heroslider}>
            {landingPage.map(item => (
              <Carousel.Item key={item.id} style={{ textAlign: 'center', position: 'relative' }}>
                <img
                  src={item.img}
                  alt={item.text}
                  style={{ objectFit: 'cover', width: "100%", height: "100%" }}
                />
                <div style={{ position: 'absolute', top: "60%", left: "37%", transform: 'translate(-50%, -50%)', textAlign: "left" }}>
                  <h4 dangerouslySetInnerHTML={{ __html: item.headerText }} style={{ color: "#fff", fontSize: 96, fontWeight: 900, margin: 0 }}></h4>
                  <h2 style={{ color: "#fff", fontSize: 20, marginTop: 10 }}>{item.text}</h2>
                  <Button onClick={() => navigate('/ministry_department_agencies')} variant="light" className={classes.heroBtns}>Ogun State MDAs</Button>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
          <div className={classes.mobileHeroCont}>
            <img src={Landing1} className={`${classes.herobg} ${classes.absolute}`} />
            <div className={classes.mobileHeroContText}>
              <h4>The Gateway State</h4>
              <h2>Ogun State was created on the 3rd of February, 1976.  It was carved out of the old Western State of Nigeria and named after the Ogun River which runs across it from North to South.</h2>
            </div>
          </div>

          {/* <Button onClick={() => navigate('#')} variant="light" className={`${classes.herobtntr} ${classes.heroBtns}`}>Ogun State MDAs</Button> */}
        </div>
        {/* <div className={classes.bgihero}> */}

        {/* <div className={classes.subText}>

            <div className={classes.subContainer}>
              <img src={Icon1} className={classes.iconBG} />
              <p className={classes.obtain}>Pay your Tax<br /> in Ogun state</p>
              <div className={classes.subB}>

                <p className={classes.subObtain}><a href="https://portal.ogetax.ogunstate.gov.ng/" target="blank">Register your Tax Identification number(TIN)</a></p>
                <p className={classes.subObtain}><a href="https://portal.ogetax.ogunstate.gov.ng/login" target="blank">Apply for Tax Clearance Certificate</a></p>

              </div>
            </div>
            <div className={classes.subContainer}>
              <img src={Icon2} className={classes.iconBG} />
              <p className={classes.obtain2}>Ease of doing business</p>
              <div className={classes.subB}>
                <p className={classes.subObtain2}><a href="http://businesspermit.ogunstate.gov.ng" target="blank">Register your Business Premise </a> </p>
                <p className={classes.subObtain2}><a href="http://eprocurements.ogunstate.gov.ng/registercompany" target="blank">Register as a contractor in Ogun State</a></p>
                <p className={classes.subObtain2}>Contractor Requirements</p>
                <p className={classes.subObtain2}>Acquire Land for your business</p>
              </div>
            </div>
            <div className={classes.subContainer}>
              <img src={Icon3} className={classes.iconBG} />
              <p className={classes.obtain3}>Find out about Ogun Statistics</p>
              <div className={classes.subB}>
                <p className={classes.subObtain3}>State Financial Statements</p>
                <p className={classes.subObtain3}>Approved Budget</p>
                <p className={classes.subObtain3}>FAQs for Open Government</p>
                <p onClick={handleOgstep} className={classes.subObtain3}>OGSTEP Documents</p>
              </div>
            </div>
            <div className={classes.subContainer}>
              <img src={Icon4} className={classes.iconBG} />
              <p className={classes.obtainfur}>Land Administration / <br />e-GIS</p>
              <div className={classes.subB4}>
                <p className={classes.subObtain4}><a href="http://olarms.ogunstate.gov.ng" target="blank">OLARMS</a></p>
               
                <p className={classes.subObtain4}>The Office of the Survey General</p>
                <p className={classes.subObtain4}>Directorate of Urban and Regional
                  Plannings</p>
              </div>
            </div>
            <div className={classes.subContainer}>
              <img src={Icon5} className={classes.iconBG} />
              <p className={classes.obtain5}>Tertiary Education Bursary/ Scholarships</p>
              <div className={classes.subB5}>
                <p className={classes.subObtain5}>Apply for Bursary </p>
                <p className={classes.subObtain5}>Eligibility Requirements</p>
                <p className={classes.subObtain5}>FAQs on Ogun State Bursaries</p>
              </div>
            </div>
          </div> */}
        {/* </div> */}

      

        <div className={classes.headkad}>
          <p className={classes.xplore}>Explore our online services</p>
          <p className={classes.xploretxt}>Do business with Ogun State at the comfort of your homes and offices with peace of mind</p>
        </div>

        <div className={`${classes.wrapper} ${classes.threeCardsbg}`}>
          <div className={`${classes.container} ${classes.threeCards}`}>
            <div className={`${classes.cards} ${classes.card4}`}>
              <div className={classes.cardItems}>
                <img src={Icon1} className={classes.eclipse} />
                <h2 className={classes.cardText}>Pay your Tax in Ogun state</h2>
                <div className={classes.subB}>
                  <div className={classes.groupedCont} >
                    {/* <img src={iconSrc} className={classes.tick} /> */}
                    <p className={classes.subObtain}><a href="https://portal.ogetax.ogunstate.gov.ng/" target="blank">Register your Tax Identification number(TIN)</a></p>
                  </div>
                  <div className={classes.groupedCont} >
                    {/* <img src={iconSrc} className={classes.tick} /> */}
                    <p className={classes.subObtain}><a href="https://portal.ogetax.ogunstate.gov.ng/login" target="blank">Apply for Tax Clearance Certificate</a></p>
                  </div>

                </div>
              </div>
            </div>

            <div className={`${classes.cards} ${classes.card4}`}>
              <div className={classes.cardItems}>
                <img src={Icon2} className={classes.eclipse} />
                <h2 className={classes.cardText}>Ease of doing business</h2>
                <div className={classes.subB}>
                  <div className={classes.groupedCont} >
                    {/* <img src={iconSrc} className={classes.tick} /> */}
                    <p className={classes.subObtain}><a href="http://businesspermit.ogunstate.gov.ng" target="blank">Register your Business Premise </a> </p>
                  </div>

                  <div className={classes.groupedCont} >
                    {/* <img src={iconSrc} className={classes.tick} /> */}
                    <p className={classes.subObtain}><a href="http://eprocurements.ogunstate.gov.ng/registercompany" target="blank">Register as a contractor in Ogun State</a></p>
                  </div>

                  <div className={classes.groupedCont} >
                    {/* <img src={iconSrc} className={classes.tick} /> */}
                    <p className={classes.subObtain}><a href="#" target="blank">Contractor Requirements</a></p>
                  </div>

                  <div className={classes.groupedCont} >
                    {/* <img src={iconSrc} className={classes.tick} /> */}
                    <p className={classes.subObtain}><a href="#" target="blank">Acquire Land for your business</a></p>
                  </div>
                </div>

              </div>
            </div>
           
            <div className={`${classes.cards} ${classes.card4}`}>
              <div className={classes.cardItems}>
                <img src={Icon3} className={classes.eclipse} />
                <h2 className={classes.cardText}>Find out about Ogun Statistics</h2>
                <div className={classes.subB}>
                  <div className={classes.groupedCont} >
                    {/* <img src={iconSrc} className={classes.tick} /> */}
                    <p className={classes.subObtain}><a href="#" target="blank">State Financial Statements </a> </p>
                  </div>

                  <div className={classes.groupedCont} >
                    {/* <img src={iconSrc} className={classes.tick} /> */}
                    <p className={classes.subObtain}><a href="#" target="blank">Approved Budget</a></p>
                  </div>

                  <div className={classes.groupedCont} >
                    {/* <img src={iconSrc} className={classes.tick} /> */}
                    <p className={classes.subObtain}><a href="#" target="blank">FAQs for Open Government</a></p>
                  </div>

                  <div className={classes.groupedCont} >
                    {/* <img src={iconSrc} className={classes.tick} /> */}
                    <p className={classes.subObtain}><a href="/ogstep_documents">Contracts & Adverts </a></p>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${classes.cards} ${classes.card4}`}>
              <div className={classes.cardItems}>
                <img src={Icon3} className={classes.eclipse} />
                <h2 className={classes.cardText}>Land Admin/e-GIS</h2>
                <div className={classes.subB}>
                  <div className={classes.groupedCont} >
                    {/* <img src={iconSrc} className={classes.tick} /> */}
                    <p className={classes.subObtain}><a href="http://olarms.ogunstate.gov.ng" target="blank">OLARMS</a></p>
                  </div>

                  <div className={classes.groupedCont} >
                    {/* <img src={iconSrc} className={classes.tick} /> */}
                    <p className={classes.subObtain}><a href="#" target="blank">The Office of the Survey General</a></p>
                  </div>

                  <div className={classes.groupedCont} >
                    {/* <img src={iconSrc} className={classes.tick} /> */}
                    <p className={classes.subObtain}><a href="#" target="blank">Directorate of Urban and Regional
                      Plannings</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`${classes.wrapper} ${classes.sectionThree}`}>
          <div className={classes.container}>

          </div>
        </div>


        <div className={`${classes.wrapper} ${classes.sectionFour}`}>
          <div className={classes.container}>
          </div>
        </div>





        <div className={`${classes.wrapper} ${classes.bgcol}`}>
          <div className={classes.container}>
            <div className={classes.automateCont}>
              {/* <div style={{ paddingLeft: '83px', marginRight: '83px' }}> */}
              <div className={classes.cont}>
                <div className={classes.thickgreen}></div>
                <h1 className={classes.firstText}>
                  GOVERNOR<br /> DAPO ABIODUN
                </h1>
                <p className={classes.secoundText}>
                  Prince Adedapo Oluseun Abiodun, <small>MFR,</small> Governor of Ogun State, Nigeria, was born on Sunday, 29th May, 1960 into the family of Dr. Emmanuel and Mrs. Victoria Abiodun of Iperu-Remo, in Ikenne Local Government of Ogun State.  Both parents were teachers.<br /><br />

                  He had his primary and secondary education in Nigeria and a tertiary education that started at the prestigious University of Ife (now Obafemi Awolowo University), Ile-Ife, Osun State, and later continued at the Kennesaw State University in Atlanta, Georgia, United States, where he graduated with a BBA in Accounting in 1986.
                  <br /><br />
                  Dapo began his career with Glock Inc. USA, where he worked as Cost Accountant between 1989-1991.  Thereafter, he returned to Nigeria and immediately embarked on entrepreneurship voyage. He was Chairman of a number of successful businesses, which include Crestar Hydrocarbons Limited, OMS-Heyden Exploration and Production Limited, Heyden Petroleum Limited, Alarmnet and Innovative Ventures Limited, and First Power Limited.
                </p>
                {/* <button className={`${classes.tryItBtn} ${classes.greenBtn}`}> Try it Now </button> */}

              </div>

              {/* </div> */}
              <div className={classes.automateImg}>
                <img src={Dapo} alt="img" className={classes.imgs} />
                {/* <img src={frame} className={classes.frame}/> */}
              </div>
            </div>
          </div>
        </div>

        {/* <div className={`${classes.wrapper} ${classes.sectionSix}`}>
          <div className={classes.container}>
          </div>
        </div> */}

{/* <Modal style={{marginTop: "2rem"}} show={showPopup} onHide={closePopup} centered size="lg">
  <Modal.Header className={classes.btmBorder} closeButton>
   
  </Modal.Header>
  <Modal.Body style={{ height: '500px', }}>
    <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
      <Viewer fileUrl="/Privacy.pdf" />
    </Worker>
  </Modal.Body>
</Modal> */}

        <div className={`${classes.wrapper} ${classes.sectionSeven}`}>
          <div className={classes.container}>
            <h5 className={classes.investBanner}>INVEST</h5>
            <h5 className={classes.investBanner1}>IN OGUN STATE</h5>
            <div className={classes.sectionText}>
              {/* <p style={{ color: "#fff" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore <br />
                magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea </p> */}
              <Button onClick={handleInvest} variant="light" className={`${classes.heroBtns} ${classes.sectionButton}`}>Invest in Ogun State</Button>
            </div>
          </div>
        </div>

        <div className={`${classes.wrapper} ${classes.sectionEight} ${active ? classes.active : ''}`}>
          <div className={`${classes.container} ${classes.sectionEightFlex}`}>
            <div className={classes.div}>
              <h6>OGUN STATE</h6>
              <h2>EMERGENCY NUMBERS</h2>
            </div>
            <div className={classes.div}>
              <h6>FIRE SERVICE</h6>
              <small style={{ color: "#6C757D" }}>ONIKOKO</small>
              <p style={{ color: "#6C757D" }}>08035692904</p>
              <small style={{ color: "#6C757D" }}>IJEMO STATION</small>
              <p style={{ color: "#6C757D" }}>08035596945</p>
              <small style={{ color: "#6C757D" }}>OTA STATION</small>
              <p style={{ color: "#6C757D" }}>08032262208</p>
              <small style={{ color: "#6C757D" }}>SAGAMU STATION</small>
              <p style={{ color: "#6C757D" }}>08065226846</p>
            </div>
            <div className={classes.div}>
              <small style={{ color: "#6C757D" }}>PPRO</small>
              <p style={{ color: "#6C757D" }}>09159578888</p>
              <small style={{ color: "#6C757D" }}>CONTROL ROOM</small>
              <p style={{ color: "#6C757D" }}>09062837609</p>
              {/* <small>OTA STATION</small> */}
              <p style={{ color: "#6C757D" }}>09120141706</p>
              {/* <small>SAGAMU STATION</small> */}
              <p style={{ color: "#6C757D" }}>09151027369</p>
              <p style={{ color: "#6C757D" }}>07084972994</p>
            </div>
          </div>
        </div>

        <div className={classes.viewgallery}>
          <Carousel slide indicators={false}>
            {carouselItems.map(item => (
              <Carousel.Item key={item.id} style={{ textAlign: 'center' }}>
                <img
                  className="d-block w-100"
                  src={item.img}
                  alt={item.text}
                  style={{ objectFit: 'cover' }}
                />
                <div className={classes.sectionNineCont} style={{ position: 'absolute', left: '50%', transform: 'translate(-50%, -50%)' }}>
                  <h4>{item.text}</h4>
                  <h2> Events and <br />Projects In Pictures</h2>
                  <Button variant="light" className={classes.heroBtns}>View Gallery</Button>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>

        </div>

        <div className={classes.viewgallerymble}>
          <div className={classes.viewgallerymbleImgBox}>
            <img src={Picture1mble} className={classes.picturemble1} />
          </div>

          <div className={classes.sectionNineCont2} style={{}}>
            {/* <h4>OGUN STATE</h4> */}
            {/* <h2> Events and <br />Projects In Pictures</h2> */}
            <Button variant="light" className={classes.heroBtnsmble}>View Gallery</Button>
          </div>
        </div>


        <div className={classes.newsSection}>
          <h3>NEWS</h3>
          <div className={classes.newsGroup}>
            <div className={classes.newsContainer}>
              <img
                className="d-block w-100"
                src={NewsIcon}
                alt="News"
                style={{ objectFit: 'cover', height: 420, borderRadius: 8, padding: 10 }}
              />
              <p>Catch up with news and events happening in and around Ogun State. </p>
            </div>

            <div className={classes.newsData1}>
              {loading ? (
                <>
                  <Spinner size="sm" />
                </>
              ) : documents.length === 0 ? (
                <p style={{ color: "black" }}>No recent news to display</p>
              ) : (
                documents.slice(0, 6).map((item, index) => (
                  <div key={index} className={classes.newsData2}>
                    <h1 onClick={() => handleReadMore(item)}>{item?.title}</h1>
                    <p>{item?.short_desc}</p>
                    <div className={classes.newsBelow}>
                      <p>Posted on {formatDate(item.created_date)}</p>
                      <h1 onClick={() => handleReadMore(item)}>Read more...</h1>
                    </div>
                  </div>
                ))
              )}




            </div>
          </div>



          <Button onClick={() => navigate('/news')} variant="success">Read more</Button>
        </div>


      </div>
      <NewFooter />
    </div>
  );
}

export default LandingPage;