import React, { useState, useEffect, useLayoutEffect } from "react";
import classes from './Executives.module.css'
import { Link, useNavigate } from "react-router-dom";
import { Button, Carousel, Spinner } from "react-bootstrap";
import HeaderNav from '../../Components/HeaderNav/HeaderNav'
import Dapo from "../../assets/executives-icon/dapoo.png";
import Noimot from "../../assets/executives-icon/noimot.png";
import CommissionerIcon from "../../assets/executives-icon/icon.png";
import SSG1 from "../../assets/ssg/talabi.png";
import SSG2 from "../../assets/ssg/afolabi.png";
import COMM1 from "../../assets/commissioners/a.png";
import COMM2 from "../../assets/commissioners/b.png";
import COMM3 from "../../assets/commissioners/c.png";
import COMM4 from "../../assets/commissioners/d.png";
import COMM5 from "../../assets/commissioners/e.png";
import COMM6 from "../../assets/commissioners/f.png";
import COMM7 from "../../assets/commissioners/g.png";
import COMM8 from "../../assets/commissioners/h.png";
import COMM9 from "../../assets/commissioners/i.png";
import footerLogo from "../../assets/Footer-logo.svg"
import logo from "../../assets/dep.png";
import NewsIcon from "../../assets/olumo.png"
import ButtonIcon from "../../assets/button.png"
import { BASE_URL, TOKEN } from "../../BaseUrl/BaseUrl";
import axios from "axios";
import NewFooter from "../../Components/NewFooter";
import NewFooter2 from "../../Components/NewFooter2";
import Swal from "sweetalert2";
// function App() {
const ContactUs = () => {
  const navigate = useNavigate();
  const [currentYear, setCurrentYear] = useState('');
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalEntries, setTotalEntries] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [checkedBox, setCheckedBox] = useState(false);


  useEffect(() => {
    const latestYear = new Date().getFullYear();
    setCurrentYear(latestYear);
  }, []);

  useLayoutEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth <= 800);
    };

    checkScreenWidth();

    window.addEventListener('resize', checkScreenWidth);
    return () => window.removeEventListener('resize', checkScreenWidth);
  }, []);


  const handleReadMore = (item) => {
    navigate('/read_news', { state: { selectedNews: item } });
  }

  const handlePost = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/create-support-ticket`,
        {
          first_name: firstName,
          last_name: lastName,
          email: email,
          phone: phone,
          description: message,
        },
        {
          headers: {
            'Authorization': `Bearer ${TOKEN}`,
            'Content-Type': 'application/json',
          }
        }
      );
      Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: response.data.message,
        });
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setMessage('');
        setCheckedBox(null);
      console.log('Ticket Created:', response.data);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Failed!',
        text: error.response.data.message,
      });
      console.error('Error:', error.response?.data || error.message);
    } finally {
      setLoading(false);
    }
  };
  

const isDisabled = !firstName || !lastName || !email || !phone || !message || !checkedBox

  const handlePrevPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
    window.scrollTo(0, 0);
  };

  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
    window.scrollTo(0, 0);
  };

  const formattedTotalEntries = totalEntries;

  function formatDate(dateString) {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    const date = new Date(dateString);
    const dayOfWeek = days[date.getDay()];
    const month = months[date.getMonth()];
    const dayOfMonth = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    const formattedDate = `${dayOfWeek}, ${month}, ${dayOfMonth} ${year} at ${hours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
    return formattedDate;
  }


  return (
    <div>

      <HeaderNav />

      <div className={classes.wrapper1}>

        <div className={classes.contactDetails}>
                  <form>
                    <div className={classes.getInTouch}>
                      <h2>Get in touch</h2>
                      <p>Our friendly team would love to hear from you.</p>
                    </div>
                    <div className={classes.topDiv}>
                      <div className={classes.contactUs}>
                        <div className={classes.divOne}>
                          <div>
                            <label className={classes.label}>First Name</label>
                            <input
                              type="text"
                              className={classes.input}
                              placeholder="First Name"
                              value={firstName}
                              onChange={(e) => setFirstName(e.target.value)}
                            />
                          </div>
                          <div>
                            <label className={classes.label}>Last Name</label>
                            <input
                              type="text"
                              className={classes.input}
                              placeholder="Last Name"
                              value={lastName}
                              onChange={(e) => setLastName(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={classes.divOne}>
        
                        <div>
                          <label className={classes.label}>Email address</label>
                          <input
                            type="email"
                            className={classes.input}
                            placeholder="you@company.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div>
                          <label className={classes.label}>Phone number</label>
                          <input
                            type="text"
                            className={classes.input}
                            placeholder="08012345678"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </div>
                      </div>
                      <div >
                        <span className={classes.label}>Message</span>
                        <br />
                        <textarea
                        style={{marginTop: -20}}
                          className={classes.textarea}
                          rows="2"
                          cols="5"
                          placeholder=""
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        />
                      </div>
                      <div className={classes.formCheck}>
                        <input type="checkbox" id="form-checking"  checked={checkedBox}
        onChange={(e) => setCheckedBox(e.target.checked)}/>
                        <label>
                          You agree to our {" "}
                          <Link className={classes.privacyLink}>Privacy Policy</Link>.
                        </label>
                      </div>
                    </div>
                    <div
  onClick={!isDisabled ? handlePost : undefined}
  className={`${classes.contactBtn} ${isDisabled ? 'cursor-not-allowed opacity-50' : ''}`}
  style={{ pointerEvents: isDisabled ? 'none' : 'auto' }}
>
  <h6 className={classes.sendMessage}>
    {loading ? (
      <>
        <Spinner size='sm' />
        <span style={{ marginLeft: '5px' }}>Processing...</span>
      </>
    ) : (
      "Send Message"
    )}
  </h6>
</div>

                  </form>
                </div>
                <div className={classes.logoDiv}>
                          <img src={logo} alt="logo" className={classes.logoBG} />
                        </div>

      </div>

      <NewFooter2 />
    </div>
  );
}

export default ContactUs;