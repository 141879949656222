import React, { useState, useEffect, useLayoutEffect } from "react";
import classes from './Privacy.module.css';
import { Link, useNavigate } from "react-router-dom";
import { Button, Carousel, Spinner } from "react-bootstrap";
import HeaderNav from '../../Components/HeaderNav/HeaderNav'
import Dapo from "../../assets/executives-icon/dapoo.png";
import Noimot from "../../assets/executives-icon/noimot.png";
import CommissionerIcon from "../../assets/executives-icon/icon.png";
import footerLogo from "../../assets/Footer-logo.svg"
import logo from "../../assets/dep.png";
import NewsIcon from "../../assets/olumo.png"
import ButtonIcon from "../../assets/button.png"
import { BASE_URL, TOKEN } from "../../BaseUrl/BaseUrl";
import axios from "axios";
import NewFooter from "../../Components/NewFooter";
import NewFooter2 from "../../Components/NewFooter2";
import * as pdfjsLib from "pdfjs-dist/build/pdf";

// import { Worker, Viewer } from "@react-pdf-viewer/core";
// import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
// import { Viewer } from "@react-pdf-viewer/default-layout";
// import "@react-pdf-viewer/core/lib/styles/index.css";
// import "@react-pdf-viewer/default-layout/lib/styles/index.css";

// function App() {
const Privacy = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [checkedBox, setCheckedBox] = useState(false);

  // const [text, setText] = useState("");
  // const defaultLayout = defaultLayoutPlugin();




  useLayoutEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth <= 800);
    };

    checkScreenWidth();

    window.addEventListener('resize', checkScreenWidth);
    return () => window.removeEventListener('resize', checkScreenWidth);
  }, []);



  // useEffect(() => {
  //   const loadPdf = async () => {
  //     const pdfUrl = "/Privacy.pdf"; // PDF in public folder
  //     pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

  //     try {
  //       const loadingTask = pdfjsLib.getDocument(pdfUrl);
  //       const pdf = await loadingTask.promise;
  //       let extractedText = "";

  //       for (let i = 1; i <= pdf.numPages; i++) {
  //         const page = await pdf.getPage(i);
  //         const textContent = await page.getTextContent();
  //         extractedText += textContent.items.map((item) => item.str).join(" ") + "\n";
  //       }

  //       setText(extractedText);
  //     } catch (error) {
  //       console.error("Error loading PDF:", error);
  //     }
  //   };

  //   loadPdf();
  // }, []);

  return (
    <div>

      <HeaderNav />

      <div className={classes.wrapper1}>

        <div className={classes.contactDetails}>
                  
                  <div  style={{
        display: "flex",
        margin: '0 auto',
        paddingTop: '20px',
        paddingBottom: '35px',
        // justifyContent: "center",
        flexDirection: 'column',
        // alignItems: "center",
        // width: "210mm",
        // max-width: 210mm;
        // height: "100vh",
        overflow: "hidden",
      }}>
      {/* <h2>Privacy Policy </h2>
      <p>{text || "Loading..."}</p>
    </div> */}
    {/* <div style={{ height: "100vh" }}> */}
    {/* <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
  <Viewer fileUrl="/Privacy.pdf"  />
</Worker> */}
        <div>
          <p style={{fontWeight: 'bold', fontSize: '20px'}}>PRIVACY POLICY </p>
          <p>This privacy policy outlines how the Ogun State website <b> (https://ogunstate.gov.ng) </b> ,Ogun State Pensioners App
collects, uses, and protects your personal information when you visit our website, in 
accordance with the Nigerian Data Protection Act (NDPA) 2023. </p>
        </div>

        <div>
          <p style={{fontWeight: 'bold', fontSize: '20px'}}>INFORMATION WE COLLECT: </p>
          <p><b>Personal Information: </b></p>
          <p>When you contact us through forms or submit inquiries, we may collect your name, email 
          address, phone number, and any other information you provide voluntarily. </p>
          <p><b>Website Usage Data: </b></p>
          <p>We automatically gather information like your IP address, browser type, operating system, 
website access times, and pages visited to analyze website usage and improve user 
experience. </p>
        </div>
        <div>
          <p style={{fontWeight: 'bold', fontSize: '20px'}}>HOW WE USE YOUR INFORMATION:</p>
          <p><b>To Respond to Inquiries:</b></p>
          <p>We use your contact details to respond to your questions, feedback, or requests for 
          information submitted through our website. </p>
          <p><b>Service Improvement: </b></p>
          <p>Website usage data helps us understand how visitors interact with our site, allowing us to 
          optimize features and content for better accessibility. </p>
          <p><b>Compliance and Legal Obligations: </b></p> 
          <p>In certain circumstances, we may disclose your information to relevant government 
          agencies to comply with legal requirements or investigations.</p>
        </div>
        <div>
          <p style={{fontWeight: 'bold', fontSize: '20px'}}>DATA SECURITY: </p>
          <p><b>Secure Storage: </b></p>
          <p>We implement appropriate security measures to protect your personal data from 
unauthorized access, disclosure, alteration, or destruction, including encryption and access 
controls. </p>
          <p> <b>Data Retention:</b> </p>
          <p>We retain your personal information only for as long as it is necessary to fulfill the purposes 
          outlined in this policy, unless required by law to retain it for longer periods. </p>
        </div>

        <div>
          <p style={{fontWeight: 'bold', fontSize: '20px'}}>YOUR RIGHTS:</p>
          <p> <b>Access to Information:</b> </p>
          <p>You have the right to request access to the personal information we hold about you.</p>
          <p> <b>Correction of Information:</b></p>
          <p>If you believe your information is inaccurate, you can request to have it corrected.</p>
          <p> <b>Objection to Processing: </b></p>
          <p>You can object to the processing of your personal data in certain circumstances. </p>
        </div>

        <div >
          <p style={{fontWeight: 'bold', fontSize: '20px'}}>How to Contact Us: </p>
          <p>If you have any questions or concerns regarding your privacy, please contact us at [email 
            address] or through the contact details provided on our website. </p>
          </div>

        <div>
        <p style={{fontWeight: 'bold', fontSize: '20px'}}>IMPORTANT NOTE:</p>
        <p>This privacy policy may be updated periodically, so please review it regularly for any 
changes. 
By using our website, you signify your acceptance of this privacy policy." </p>
        </div>
        <div>
          <p style={{fontWeight: 'bold', fontSize: '20px'}}>KEY POINTS TO CONSIDER:</p>
          <p><b>Explicit Consent: </b></p>
          <p>Clearly state when you require explicit consent to collect sensitive personal data. </p>
          <p> <b>Third-Party Services:</b></p>
          <p>If you integrate third-party services on your website, ensure they comply with the NDPA 
          and have their own privacy policies. </p>
          <p><b>Data Minimization:</b> </p>
          <p>Collect only the necessary personal information for the intended purpose. </p>
          <p> <b>Compliance with Data Protection Laws: </b></p>
          <p>Regularly review and update your privacy policy to align with current Nigerian data 
          protection regulations. </p>
        </div>

    </div>
                 
                </div>
                {/* <div className={classes.logoDiv}>
                          <img src={logo} alt="logo" className={classes.logoBG} />
                        </div> */}

      </div>

      <NewFooter2 />
    </div>
  );
}

export default Privacy;